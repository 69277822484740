import React from "react";
import { styled } from "@linaria/react";
import SlideAndFadeOnScroll from "components/Animations/SlideAndFadeOnScroll/SlideAndFadeOnScroll";
import LiveIndicator from "components/LiveIndicator";
import VisitedIndicator from "components/VisitedIndicator";
import { ratio } from "components/RatioWrapper/RatioWrapperBase";
import AdaptiveImage from "features/AdaptiveImg/AdaptiveImage";
import { getImageOptimizedSources } from "features/AdaptiveImg/utils/getImageSources";
import getImgSizes from "features/AdaptiveImg/utils/getSizes";
import Card, { CardProps } from "features/Card/Card";
import { ViewingRoomContextNavigationMode } from "features/ContextNavigation/store/contextNavigationSlice";
import { primaryFont, secondaryFont } from "styles";
import { down, up } from "styles/utils/breakpoints";
import em from "styles/utils/em";
import fluid from "styles/utils/fluid";
import { ViewingRoom } from "../interfaces/viewing-room.interfaces";
import { viewingRoomRoutes } from "../viewing-room.routes";
import SaveViewingRoomCardAction from "./SaveViewingRoomCardAction";
import ViewingRoomNavigationLink from "./ViewingRoomNavigationLink";
import viewingRoomStorage from "utils/viewingRoomsStorage";
import SignTheBookCardAction from "./SignTheBookCardAction";

const IndicatorsWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 6px;
  left: 6px;
`;

export const ViewingRoomCardBase = styled(Card)`
  & .image-wrapper {
    ${ratio(363 / 268)}
  }
  & .primary-text-wrapper {
    font-family: ${primaryFont};
    font-size: 19px;
    line-height: ${em("26px", "22px")};
    letter-spacing: ${em("0.3px", "21px")};

    & > a {
      max-width: 100%;
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ${up("lg")} {
      font-size: 19px;
      line-height: ${em("20px", "16px")};
      letter-spacing: ${em("0.3px", "16px")};
    }

    ${fluid("margin-top", {
      lg: "6px",
      xlg: "14px",
    })}
  }

  &.secondary .primary-text-wrapper {
    font-family: ${primaryFont};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: ${em("0.6px", "22px")};
    line-height: ${em("24px", "22px")};
    text-transform: uppercase;
  }

  &.secondary .secondary-text {
    margin-top: 6px;
    font-family: ${secondaryFont};
    font-size: 21px;
    letter-spacing: ${em("0.4px", "21px")};
  }
  .action-wrapper {
    button {
      font-size: 14px;
      ${down("sm")} {
        font-size: 18px;
      }
    }
  }
`;

export interface ViewingRoomCardProps {
  item: ViewingRoom;
  mode?: ViewingRoomContextNavigationMode;
}

export interface ViewingroomCardPropsExtended extends ViewingRoomCardProps, Partial<CardProps> {}

const ViewingRoomCard = ({ mode, item, ...props }: ViewingroomCardPropsExtended) => {
  const visitedRooms = viewingRoomStorage.getIds();
  const isVisited = () => {
    return visitedRooms.includes(item.id);
  };

  return (
    <ViewingRoomCardBase
      {...props}
      primaryText={
        typeof props.primaryText !== "undefined" ? (
          props.primaryText
        ) : (
          <ViewingRoomNavigationLink addGoBackState saveScroll to={viewingRoomRoutes.link(item.id)} mode={mode}>
            {item.name}
          </ViewingRoomNavigationLink>
        )
      }
      image={
        typeof props.image !== "undefined" ? (
          props.image
        ) : (
          <>
            <AdaptiveImage
              sizes={getImgSizes({
                base: "calc(100vw - 40px)",
                xs: "86vw",
                sm: "calc(36vw - 17px * 2)",
                lg: "calc(50vw - 90px * 2 - 17px * 4)",
              })}
              sources={getImageOptimizedSources(item, item.isBestPreviewEnabled)}
            />
            <IndicatorsWrapper>
              {/* {!!item.isChatOnline && <LiveIndicator />} */}
              {isVisited() ? <VisitedIndicator /> : null}
            </IndicatorsWrapper>
          </>
        )
      }
      action={
        typeof props.action !== "undefined" ? (
          props.action
        ) : (
          <Flex>
            <SignTheBookCardAction viewingRoom={item} />
            <SaveViewingRoomCardAction viewingRoom={item} />
          </Flex>
        )
      }
    />
  );
};

export default ViewingRoomCard;

export const VrCardNoAction = (props: ViewingroomCardPropsExtended) => {
  return (
    <SlideAndFadeOnScroll>
      <ViewingRoomCard {...props} />
    </SlideAndFadeOnScroll>
  );
};
export const FadedVrCardOnScroll = (props: ViewingroomCardPropsExtended) => {
  return (
    <SlideAndFadeOnScroll>
      <ViewingRoomCard {...props} />
    </SlideAndFadeOnScroll>
  );
};

const Flex = styled.div`
  display: flex;
  gap: 10px;
`;
